*,
ul,
ol {
  margin: 0;
  padding: 0;
}

:focus-visible {
  outline: none !important;
}

a,
a:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  transition: 0.4s;
}

button {
  cursor: pointer;
  user-select: none;
  border: none;
  padding: 0;
  background: unset;
  transition: 0.4s;
}

input {
  color: $color-text;

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    -webkit-background-clip: text;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  &:focus {
    &::placeholder {
      opacity: 0;
    }
  }

  &::placeholder {
    color: $color-text !important;
  }

  &[readonly] {
    cursor: not-allowed;
  }
}

input,
button,
select {
  height: 48px;
}

label {
  transition: transform 0.4s, font-size 0.4s;
}

input,
input:focus,
textarea,
textarea:focus {
  border: none;
  outline: none;
  background: unset;
}

a,
button,
input,
textarea,
select {
  &:focus {
    box-shadow: 0 0 0 0.25rem rgba($color-text, 0.25) !important;
    border-color: unset !important;
    // border-radius: inherit;
    outline: none;
  }
}

textarea {
  resize: none !important;
}

select {
  appearance: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: normal;
}

ul,
ol {
  list-style-type: none;
}

html,
body {
  scroll-behavior: smooth;

  &::-webkit-scrollbar {
    width: 0;
    display: none;
  }
}

body {
  user-select: none;
  color: $color-text;
  overflow-x: hidden;
  font-family: $font-family-raleway;
  padding-bottom: 60px;
}

.body--fixed {
  overflow: hidden;
  width: 100%;
  height: 100%;
}
