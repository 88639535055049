.js-icon {
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  height: max-content;

  &-button {
    width: 19px;
    height: 19px;
    color: black;
    font-size: 19px;
  } 

  &:focus {
    border-radius: 2px !important;
  }
}
