
.p {
  &-tag {
    font-size: 0.75rem;
    font-weight: 700;
    padding: 0.25rem 0.4rem;
    border-radius: 6px;
    font-family: $font-family-raleway!important;

    &-success {
      border: 1px solid #092319;
      background: #E7FAF4;
      color: #092319;
    }

    &-warning {
      border: 1px solid #937300;
      background: #FFF4CC;
      color: #937300;
    }

    &-danger {
      border: 1px solid #930000;
      background: #FFEBEB;
      color: #930000;
    }
  }
}