.dropdown {
    &--section {
        width: 232px;

        li:nth-child(odd) {
            border-radius: 0.375rem 0.375rem 0 0;

            a {
                border-radius: 0.375rem 0.375rem 0 0;
            }
        }

        li:last-child {
            border-radius: 0 0 0.375rem 0.375rem;

            a {
                border-radius: 0 0 0.375rem 0.375rem;
            }
        }
  
        &-inset {
          inset: -12px 0px auto auto!important;
        }
    }
}