@keyframes slide-right {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100px);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
