.border {
  --bs-border-style: solid;

  &--blue {
    border-color: $color-blue !important;
  }

  &--dark-yellow {
    border-color: $color-dark-yellow !important;
    --bs-border-opacity: 1;
  }

  &--red {
    border-color: $color-error-text !important;
    --bs-border-opacity: 1;
  }

  &--green {
    border-color: $color-success-text !important;
    --bs-border-opacity: 1;
  }

  &--gray {
    border-color: $color-med-gray !important;
  }

  &-radius-20 {
    border-radius: 20px !important;
  }

  &__width {
    &--2 {
      border-width: 2px !important;
    }
  }
}
// .border {
//   --bs-border-style: solid;
// 
//   &--green {
//     border-color: $color-green !important;
//   }
// 
//   &--dark {
//     border-color: $color-dark !important;
//   }
// 
//   &--darkYellow {
//     border-color: $color-dark-yellow !important;
//   }
// 
//   &--darkBronze {
//     border-color: $color-dark-bronze !important;
//   }
// 
//   &--darkGray {
//     border-color: $color-dark-gray !important;
//   }
// 
//   &--white {
//     border-color: $color-white !important;
//   }
// }
