table {
    th {
        width: auto;
    }

    th .p-column-header-content span .p-icon.p-sortable-column-icon {
        display: none;
    }

    th[aria-sort="none"] .p-column-header-content span:last-child::after{
        content: ""!important;
    }
    th[aria-sort="descending"] .p-column-header-content span:last-child::after{
        content: "\f0d7" !important;  
        font-family: FontAwesome;
        font-size: 1rem !important;
        padding-left: 10px;
    }
    th[aria-sort="ascending"] .p-column-header-content span:last-child::after{
        content: "\f0d8" !important;  
        font-family: FontAwesome;
        font-size: 1rem !important;
        padding-left: 10px;
    }
}

.p-stepper-header button#pr_id_1_0_header_action {
        
    .p-stepper-number {
        background-image: url(../../../public/img/icon-preference-white.png);
        width: 56px;
        height: 56px;
        background-repeat: no-repeat;
        background-position: center;
    }
}   

button#pr_id_1_1_header_action {
    
    .p-stepper-number {
        background-image: url(../../../public/img/icon-visa-type-white.png);
        width: 56px;
        height: 56px;
        background-repeat: no-repeat;
        background-position: center;
    }
}   

.p-stepper {

    &-action {
        display: block;
        height: 100%;

        
        
    }

    

    

    &.p-component {
        background-color: white;
        padding: 2rem;
        border-radius: 120px;
        display: flex;
    }

    &-nav {
        margin: auto 0;
        overflow-x:unset;
        width: 100%;
    }

    li{
        background: none!important;
        .p-highlight{
            width: 56px;
            height: 56px;
            border-radius: 50px;
            background: none!important;
            
        
            &.p-stepper-number {
                background-color: $color-blue!important;
            }
        }
    } 
}

