.trigger {
  @include lg {
    display: none;
  }

  &:checked {
    + label > .header__burger > span {
      &:first-of-type,
      &:nth-of-type(4),
      &:last-of-type {
        background-color: transparent !important;
      }

      &:first-of-type {
        top: 9.5px;
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        left: 0;
        width: 30px !important;
      }

      &:nth-of-type(2) {
        transform: rotate(45deg);
      }

      &:nth-of-type(3) {
        transform: rotate(-45deg);
      }

      &:last-of-type {
        bottom: 9.5px;
      }
    }
  }

  ~ label {
    z-index: 5;
  }
}
