.bg {
  &--login {
    background: url(../../../public/img/bg-login-mobile.png) center/cover no-repeat;
    margin: 16px 0;

    @include md {
      background: url(../../../public/img/bg-login.png) center/contain no-repeat;
    }
  }

  &--light-gray {
    background-color: $color-light-gray !important;
  }

  &--blue {
    background-color: $color-blue !important;
  }

  &--light-yellow {
    background-color: $color-light-yellow !important;
  }

  &--light-red {
    background-color: $color-error !important;
  }

  &--light-green {
    background-color: $color-success !important;
  }
}
