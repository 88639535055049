.form-control,
.form-select,
.dropdown button {
  font-size: $font-size-14;

  &:focus {
    box-shadow: unset;
    border-color: unset;
  }
}

.form-check {
  &-input {
    width: 24px;
    height: 24px;
  }
}

.form-check {
  // display: flex;
  // gap: 15px;
  // align-items: center;
  // width: 49%;

  // input {
  //   min-width: 24px;
  //   height: 24px;
  //   margin-left: 0;
  //   border: 2px solid $color-blue;
  // }
}

.checkbox-options {
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;

  &.yes-no-option {
    flex-wrap: unset;
    height: 48px;

    .form-check {
      width: 30%;
    }
  }
}

.form-check-input:checked {
    background-color: $color-blue;
}

.form-check-label {
  // font-size: 14px;
  // font-weight: 500;
}

.form-check-input {
  &:focus {
    border-color: $color-blue !important;
  }
}

.accordion {
  --bs-accordion-btn-color: #161616;

  &-button {
    height: unset;
  
    &:not(.collapsed) {
      background-color: transparent;
      color: $color-dark-blue;
      box-shadow: none;
    }

    &[aria-expanded="true"] {
      font-weight: bold !important;
    }
  }
}

.modal {
  --bs-modal-margin: 16px;
  backdrop-filter: blur(5px);

  &-dialog {
    max-width: 537px;
  }

  &#modal-viewClientReservation,
  &#modal-reviewDetails {
    .modal-dialog {
      max-width: 584px;
    }
  }

  &-footer {
    & > * {
      width: 195px;
    }
  }
}

.breadcrumb {
  --bs-breadcrumb-divider: url('data:image/svg+xml,%3Csvg xmlns=\'http://www.w3.org/2000/svg\' width=\'8\' height=\'8\'%3E%3Cpath d=\'M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z\' fill=\'%236c757d\'/%3E%3C/svg%3E');
}

.pagination {
  --bs-pagination-font-size: 14px;
  --bs-pagination-color: #161616;
  --bs-pagination-active-bg: #005cb2;
  --bs-pagination-hover-color: #161616;
  --bs-pagination-hover-bg: #f4f4f4;
  --bs-pagination-active-color: #fff;
  --bs-pagination-border-width: 0;
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(9, 35, 25, 0.4);
  --bs-pagination-disabled-bg: transparent;
  --bs-pagination-focus-color: #161616;

  .paginate_button {
    width: 40px;
    height: 40px;

    &.disable,
    &.dots {
      .page-link {
        &:hover,
        &:focus {
          background-color: transparent;
          cursor: auto;
          box-shadow: unset;
        }
      }
    }
  }

  .page {
    &-item {
      .page-link {
        border-radius: 50%;
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

.alert {
  --bs-alert-border-color: transparent;
  --bs-success-bg-subtle: #ebfbf5;
  --bs-danger-bg-subtle: #fdedef;

  .btn-close {
    --bs-btn-close-bg: none;
    --bs-btn-close-opacity: 1;
    --bs-btn-close-hover-opacity: 1;
  }

  &.fade:not(.show) {
    display: none;
  }

  &-danger {
    .btn-close {
      color: $color-error; 
    }
  }

  &-success {
    .btn-close {
      color: $color-gray;
    }
  }
}

.table {
  & > :not(caption) > * > * {
    padding: 18px !important;
  }
}

.overflow-y-auto {
  &::-webkit-scrollbar {
    width: 8px;
    background-color: $color-med-gray;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $color-dark-blue;
    border-radius: 8px;
  }
}

.dropdown {
  &-menu {
    --bs-dropdown-link-active-bg: #092319;
  }
  
  
  &-select {
    &:focus-within {
      &::after {
        transform: translateY(0) rotate(180deg);
      }
    }
  
    &::after {
      content: '\f0dd';
      font: var(--fa-font-solid);
      border: none;
      transition: transform 0.4s;
      position: absolute;
      right: 20px;
      top: 45%;
      transform: translateY(-50%);
    }
  }
}

.position {
  @include md {
    &-md {
      &-absolute {
        position: absolute !important;
      }

      &-static {
        position: static !important;
      }
    }
  }
}

.rounded-6 {
  border-radius: 50px;
}
