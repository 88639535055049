.panel {
  padding: 24px 16px;

  @include lg {
    padding: 24px;
  }

  &--active {
    transform: translateX(0) !important;
  }

  &--mobile {
    transform: translateX(105%);
    z-index: 4;
    transition: transform 0.4s 0.6s;
    box-shadow: 0 0 17px 0 rgba($color-black, 0.6);
  }

  ~ .section {
    @include md {
      position: absolute;
    }
  }

  &__image {
    width: 24px;
  }

  &__navigation {
    margin-top: 136px;
  }

  a,
  button {
    &:hover {
      background-color: $color-light-gray;
      color: $color-blue;

      .panel__image img.dashboard {
        content: url('../../../public/icon/icon-dashboard-blue.png');
      }
      .panel__image img.profile {
        content: url('../../../public/icon/icon-card-profile-blue.png');
      }
      .panel__image img.applicants {
        content: url('../../../public/icon/icon-new-applicants-blue.png');
      }
      .panel__image img.rules {
        content: url('../../../public/icon/icon-rules-blue.png');
      }
      .panel__image img.renewals {
        content: url('../../../public/icon/icon-renewals-blue.png');
      }
      .panel__image img.accounts {
        content: url('../../../public/icon/icon-accounts-blue.png');
      }
      .panel__image img.logout {
        content: url('../../../public/icon/icon-logout-blue.png');
      }
    }
  }

  .company-logo {
    width: 70px;
    height: 70px;

    @include md {
      width: 80px;
      height: 80px;
    }
  }

  .panel__navigation {
    margin-top: 0;
  }

  .copyright {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
  }

  &.retiree {
    box-shadow: 0px 3px 11px 0px rgba(125, 125, 125, 0.25);
    padding: 24px 4%;

    @include md {
      padding: 24px 5%;
    }
    
    @include lg {
      padding: 24px 10%;
    }

    .menu {
      justify-content: flex-end;

      div {
        margin-right: 30px;
        align-content: center;

        &.profile {
          img {
            margin-right: 10px;
            width: 48px;
            height: 48px;
            border-radius: 100px;
            border: 2px solid #D9D9D9;
          }
        }

        &:last-of-type {
          margin-right: 0;
        }
      }
    }

    .icon-arrow-down {
      &::after {
        content: url('../../../public/img/icon-arrow-down.svg');
      }

      &:hover {
        cursor: hand;
      }
    }
  }
}
