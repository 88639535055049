/* In your CSS file */
.height-important {
    height: 2.2vw !important;
}

.from-\[\#A9A9A8\] {
    --tw-gradient-from: #A9A9A8 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(169 169 168 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.from-\[\#005DB3\] {
    --tw-gradient-from: #005DB3 var(--tw-gradient-from-position);
    --tw-gradient-to: rgb(0 93 179 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

/* .via-transparent {
    --tw-gradient-to: rgb(0 0 0 / 0) var(--tw-gradient-to-position);
    --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
} */

.to-\[\#A9A9A8\] {
    --tw-gradient-to: #A9A9A8 var(--tw-gradient-to-position);
}

.to-transparent {
    --tw-gradient-to: transparent var(--tw-gradient-to-position);
}

.to-\[\#005DB3\] {
    --tw-gradient-to: #005DB3 var(--tw-gradient-to-position);
}

.text-\[\#005DB3\] {
    --tw-text-opacity: 1;
    color: rgb(0 93 179 / var(--tw-text-opacity));
}

.text-\[\#A9A9A8\] {
    --tw-text-opacity: 1;
    color: rgb(169 169 168 / var(--tw-text-opacity));
}