.header {
  height: 100px;

  &__image {
    width: 80px;
    transition: opacity 0.5s;

    &--profile {
      width: 40px;
    }
  }

  &__burger {
    cursor: pointer;
    transform: rotate(0deg);
    width: 32px;
    height: 24px;
    z-index: 3;

    &-line {
      height: 4px;
      transition: all 0.4s ease-in-out, transform 0.4s ease-in-out 0.4s;

      &:first-of-type {
        top: 0;
      }

      &:nth-of-type(2),
      &:nth-of-type(3) {
        top: 9.5px;
        width: 1px !important;
        left: 9.5px;
      }

      &:nth-of-type(2) {
        transform: rotate(90deg);
      }

      &:nth-of-type(3) {
        right: 0;
      }

      &:nth-of-type(4) {
        top: 9.5px;
      }

      &:last-of-type {
        bottom: 0;
      }
    }
  }

  a {
    &:hover {
      background-color: $color-light-gray;
    }
  }
}
