@mixin lg {
  @media screen and (min-width: 992px) {
    @content;
  }
}

@mixin md {
  @media screen and (min-width: 768px) {
    @content;
  }
}
