.form {
  &__input {
    margin-top: 24px;

    &:focus {
      box-shadow: unset !important;

      & + label {
        font-size: $font-size-12;
        transform: translate(16px, 16px);
      }
    }

    &--password {
      padding-right: 40px !important;

      ~ .fas {
        opacity: 0;
        right: 24px;
        top: 40px;
        transition: opacity 0.4s;
      }

      &.js-input-focused {
        ~ .fas {
          opacity: 1;
          cursor: pointer;
        }
      }
    }
  }
}
