.fs {
  &-10 {
    font-size: $font-size-10;
  }

  &-12 {
    font-size: $font-size-12;
  }

  &-14 {
    font-size: $font-size-14;
  }

  &-16 {
    font-size: $font-size-16;
  }

  &-18 {
    font-size: $font-size-18;
  }

  &-20 {
    font-size: $font-size-20;
  }

  &-24 {
    font-size: $font-size-24;
  }

  &-30 {
    font-size: $font-size-30;
  }

  &-36 {
    font-size: $font-size-36;
  }

  &-48 {
    font-size: $font-size-48;
  }

  &-60 {
    font-size: $font-size-60;
  }

  &-72 {
    font-size: $font-size-72;
  }
}

@include md {
  .fs-md {
    &-10 {
      font-size: $font-size-10;
    }

    &-12 {
      font-size: $font-size-12;
    }

    &-14 {
      font-size: $font-size-14;
    }

    &-16 {
      font-size: $font-size-16;
    }

    &-18 {
      font-size: $font-size-18;
    }

    &-20 {
      font-size: $font-size-20;
    }

    &-24 {
      font-size: $font-size-24;
    }

    &-30 {
      font-size: $font-size-30;
    }

    &-36 {
      font-size: $font-size-36;
    }

    &-48 {
      font-size: $font-size-48;
      line-height: 56px;
    }

    &-60 {
      font-size: $font-size-60;
    }

    &-72 {
      font-size: $font-size-72;
    }
  }
}

@include lg {
  .fs-lg {
    &-10 {
      font-size: $font-size-10;
    }

    &-12 {
      font-size: $font-size-12;
    }

    &-14 {
      font-size: $font-size-14;
    }

    &-16 {
      font-size: $font-size-16;
    }

    &-18 {
      font-size: $font-size-18;
    }

    &-20 {
      font-size: $font-size-20;
    }

    &-24 {
      font-size: $font-size-24;
    }

    &-30 {
      font-size: $font-size-30;
    }

    &-36 {
      font-size: $font-size-36;
    }

    &-48 {
      font-size: $font-size-48;
      line-height: 56px;
    }

    &-60 {
      font-size: $font-size-60;
    }

    &-72 {
      font-size: $font-size-72;
    }
  }
}

.fc {
  &-color-text {
    color: $color-text;
  }

  &-blue {
    color: $color-blue;
  }

  &-bluish-gray {
    color: $color-bluish-gray;
  }

  &-gray {
    color: $color-gray;
  }

  &-success {
    color: $color-success-text;
  }

  &-error {
    color: $color-error-text;
  }

  &-darkGray {
    // color: $color-dark-gray;
  }

  &-darkBronze {
    // color: $color-dark-bronze;
  }

  &-darkYellow {
    color: $color-dark-yellow;
  }
}
