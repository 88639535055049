// color
$color-dark-blue: #0d2147;
$color-blue: #005cb2;
$color-success-text: #092319;
$color-success: #e7faf4;
$color-error-text: #930000;
$color-error: #feeaed;
$color-light-yellow: #fff6d7;
$color-dark-yellow: #877054;
$color-dark-gray: #2d2d2d;
$color-bluish-gray: #4e5265;
$color-gray: #9a9a9a;
$color-med-gray: #ddd;
$color-light-gray: #f5f5f5;
$color-text: #161616;
$color-black: #000;
$color-white: #fff;

// font-size
$font-size-8: 8px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-24: 24px;
$font-size-30: 30px;
$font-size-36: 36px;
$font-size-48: 48px;
$font-size-60: 60px;
$font-size-72: 72px;

// font-family
$font-family-fontawesome: 'Font Awesome 6 Free';
$font-family-raleway: 'Raleway', sans-serif;

@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,400;0,600;0,700;1,400&display=swap');
