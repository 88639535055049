.dt {
  &-container {
    margin-top: 56px;
  }

  &-input {
    // padding: 16px 32px 16px 16px !important;
    border: 2px solid $color-dark-blue !important;
  }

  &-length {

    select {
      text-align: center;
      margin-right: 5px;
      width: auto;
      padding: 14px !important;
      font-size: 14px;
      appearance: auto;
    }

  }

  &-search {
    label {
      position: absolute;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      right: 18px;
      top: 28px;
    }

    input {
      padding: 16px 32px 16px 16px !important;
      font-size: 14px;
    }
  }

  &-ordering-desc {
    opacity: 1;
  }

  &-ordering-asc {
    opacity: 1;
  }

  &-column-order::after {
    content: "\f0d7" !important;  
    font-family: FontAwesome;
    font-size: 22px !important;
  }

  &-column-order::before {
    font-family: FontAwesome;
    content: "\f0d8" !important;
    font-size: 22px !important;
  }

  &-layout-row {
    margin-bottom: 80px;
  }

}

div.dt-container .dt-paging .dt-paging-button {
  &.current,
  &:active,
  &.current:hover {
    background: $color-blue !important;
    color: $color-white !important;
  }

  &:focus {
    box-shadow: none !important;
  }

  &:hover {
    background-color: $color-light-gray !important;
    color: $color-black !important;
    background: none;
  }

  width: 40px;
  height: 40px;
  border-radius: 50% !important;
  border: 0px !important;

  
}

.js-table {
  thead tr th {
    border-bottom: 2px solid $color-text !important;
  }
}


table.dataTable {
  tbody {
    td.max-w-150 {
      max-width: 150px;
    }
  }

  thead {
    th:hover {
      outline: none!important;
    }

    tr > th.dt-orderable-asc.dt-orderable-desc span.dt-column-order:before,
    tr > th.dt-orderable-asc.dt-orderable-desc span.dt-column-order:after{
      opacity: 0;
    }

    tr > th.dt-orderable-asc.dt-orderable-desc.dt-ordering-asc span.dt-column-order:before,
    tr > th.dt-orderable-asc.dt-orderable-desc.dt-ordering-desc span.dt-column-order:after {
      opacity:1;
      top: 50%;
    }

    tr > th.dt-orderable-asc.dt-orderable-desc.dt-ordering-asc span.dt-column-order:after,
    tr > th.dt-orderable-asc.dt-orderable-desc.dt-ordering-desc span.dt-column-order:before {
      opacity:0;
    }
    
  }


}

.p {
  &-column-header {
    &-content {
      justify-content: center;
    }
  }

  &-datatable {
    margin-top: 58px;

    &-header {
      margin-bottom: 80px;
      background: none !important;

      span {
        input {
          border: 2px solid $color-dark-blue !important;
          padding: 16px 32px 16px 16px !important;
          font-size: 14px;
          border-radius: 8px;
          font-weight: normal;
          font-family: $font-family-raleway!important;
          
          &:focus {
            box-shadow: unset !important;
          }
        }

        i {
          position: absolute;
          -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
          transform: translateY(-50%);
          right: 18px;
          top: 25px;
        }
      }
    }

    &-thead {
      border-bottom: 2px solid #161616;

      th {
        padding: 18px;
        background: none !important;
        font-family: $font-family-raleway!important;
      }
    }
    &-tbody {
      tr {
        background: none !important;
        font-family: $font-family-raleway!important;
      }
      td {
        text-align: center;
        padding: 18px;
      }
      td:last-child {
        width: 100px;
      }
    }
  }

  &-paginator {
    margin-top: 80px;
    justify-content: end;
    background: none !important;

    &-element {
      padding: 8px 16px;
      border-radius: 50% !important;
      border: 0px !important;

      &:focus {
        box-shadow: unset !important;
      }
    }

    &-page {
      width: 40px;
      height: 50px;
      font-weight: bold;
      font-family: $font-family-raleway!important;
    }

    .p-dropdown {
      border: 2px solid $color-dark-blue !important;
      padding: 10px 16px !important;
      font-size: 14px;
      border-radius: 8px;
      background: transparent!important;

      &-label {
        font-family: $font-family-raleway!important;
      }
    }
  }

  &-dropdown-panel {
    border: 1px solid $color-dark-blue !important;
    border-radius: 3px;
    text-align: center;
  }

  &-sortable-column{
    text-align: center;
  }

  &-sortable-column.p-highlight {
    background: inherit !important;
    color: inherit !important;
  }

  &-highlight {
    background: $color-blue !important;
    color: $color-white !important;
  }
}