.overlay {
  background-color: rgba($color-black, 0.2);
  backdrop-filter: blur(4px);

  &--active {
    opacity: 1;
    z-index: 3;
    visibility: visible !important;
    display: block !important;
    transition: ease-in-out 0.3s 0.7s;
    // animation: fade-in 1.2s cubic-bezier(0.39, 0.57, 0.56, 1) both;
  }
}
