.button {
  &.border {
    &--blue {
      &:hover {
        background-color: $color-blue;
        color: $color-white;
      }
    }
  }

  &.bg {
    &--blue {
      &:hover {
        background: #003D8B !important;
        box-shadow: 0 0 10px rgba($color-blue, 0.7);
      }
    }
  }

  &--section {
    width: 232px;

    @include md {
      width: 200px;
    }
  }

  &--retiree {
    width: 240px;
    height: 240px;

    &:hover {
      transform: translateY(-16px);
    }
  }

  &--application {
    padding: 20px 30px!important;
    height: 100px;
    width: 275px;
  }
}

button.icon {
  box-shadow: none !important;
  height: fit-content;
}