.section {
  padding: 104px 0;

  &--login {
    .company-logo {
      width: 90px;
      height: 90px;
    }

    .card {
      @include md {
        width: 440px;
      }
    }
  }

  .nav-pills {
    margin-bottom: 0;
    border-bottom: 2px solid #005cb2;

    .nav-link {
      height: 56px;
      flex-shrink: 0;
      font-size: 14px;
      border-radius: 0;
      color: #005cb2;
      font-weight: 700;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px 8px 0 0;

      &:not(.active) {
        &:hover {
          opacity: 0.5;
        }
      }

      .tab-pill-image {
        width: 19px;
        height: 19px;
        flex-shrink: 0;
      }

      &.active {
        background: #005cb2;
        box-shadow: 0 0 24.2px 0 rgba(0, 0, 0, 0.25);
        margin-right: 0 !important;
        color: #fff;

        .tab-pill-image {
          color: #fff;
        }
      }

      &#nav-principal-retiree {
        .tab-pill-icon {
          &::after {
            content: url('../../../public/img/icon-principal-retiree-blue.png');
          }
        }
      }

      &#nav-principal-retiree.active {
        .tab-pill-icon {
          &::after {
            content: url('../../../public/img/icon-principal-retiree-white.png');
          }
        }
      }

      &#nav-family-member {
        .tab-pill-icon {
          &::after {
            content: url('../../../public/img/icon-family-member-blue.png');
          }
        }
      }

      &#nav-family-member.active {
        .tab-pill-icon {
          &::after {
            content: url('../../../public/img/icon-family-member-white.png');
          }
        }
      }

      &#nav-parents {
        .tab-pill-icon {
          &::after {
            content: url('../../../public/img/icon-parents-blue.png');
          }
        }
      }

      &#nav-parents.active {
        .tab-pill-icon {
          &::after {
            content: url('../../../public/img/icon-parents-white.png');
          }
        }
      }

      &#nav-education-and-career {
        .tab-pill-icon {
          &::after {
            content: url('../../../public/img/graduation-cap-blue.png');
          }
        }
      }

      &#nav-education-and-career.active {
        .tab-pill-icon {
          &::after {
            content: url('../../../public/img/icon-education-white.png');
          }
        }
      }

      &#nav-attachments {
        .tab-pill-icon {
          &::after {
            content: url('../../../public/img/icon-attachment-blue.png');
          }
        }
      }

      &#nav-attachments.active {
        .tab-pill-icon {
          &::after {
            content: url('../../../public/img/icon-attachment-white.png');
          }
        }
      }
    }
  }

  .breadcrumb {
    .icon-arrow-right {
      content: url('../../../public/img/Vector.svg');
      padding: 0 10px;
    }

    .applicant-name {
      color: #9A9A9A;
      font-size: 12px;
      font-style: normal;
      font-weight: 600;
    }
  }

  #attachments {
    p {
      color: #4E5265;
    }

    .fs-14 {
      &.text-uppercase {
        font-weight: 600;
      }
    }

    a {
      text-decoration: underline;
    }
  }

  &--dashboard {
    .icon {
      width: 48px;
      height: 48px;
      padding: 10px;
    }

    .card {
      padding: 32px;
    }
  }

  @include md {
    // max-width: calc(100% - 230px) !important;
  }

  @include lg {
    max-width: calc(100% - 241px) !important;
  }

  &__container {
    @include lg {
      max-width: calc(100% - 350px) !important;
    }
  }

  &--icon {
    &-more {
      width: 19px;
      bottom: -3px;
      position: relative;
      margin-right: 0!important;
    }
  }

  &--badge {
    width: 100px;
    height: 25px;
  }

  &--file {
    &-signature {
      padding: 10px;
    }
  }

  &--profile-img {
    width: 136px;
    height: 136px;
  }

  &--verify-icon {
    width: 86px;
    height: 86px;
    margin: auto;
  }

  &--subtitle {
    font-weight: 600;
    color: $color-gray;
    text-align: center;
    line-height: normal;
  }

  &--signature {
    &-img {
      width: 40px;
      height: 40px;
      position: relative;
      top: -13px;
    }
  }

  &--footer {
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

}

.retiree-section-container {
  max-width: 100% !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;

  .banner {
    &.retiree-profile {
      img {
        width: 379px;
        height: 214px;
      }

      .position-absolute {
        top: 175px;
      }
    }

    &.retiree-other-services {
      background: url(../../../public/img/bg-other-services.png) bottom/cover no-repeat fixed;
    }

    &.retiree-application {
      background: url(../../../public/img/bg-application-form.png) top/cover no-repeat fixed;
    }

    &.retiree-home {
      img {
        width: 217px;
        height: 232px;
      }
    }

    &.retiree-application,
    &.retiree-other-services {
      height: 250px;
      
      @include md {
        height: 500px;
      }
    }

    &.retiree-profile,
    &.retiree-home {
      background: url(../../../public/img/bg-retiree-home.png) center/cover no-repeat;

      .page-text {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    }

    &.retiree-home,
    &.retiree-profile,
    &.retiree-form {
      height: 350px;
    }

    &.retiree-form {
      background: url(../../../public/img/bg-application-form-step.png) center/cover no-repeat;

      img {
        width: 217px;
        height: 232px;
      }

      &.stepper-card{
        background-color: white;
        padding: 2rem;
        border-radius: 10px;
        margin-bottom: 1rem;
      
      }
    }

    display: flex;
    align-items: center;

    .page-text {
      color: #fff;

      @include md {
        padding: 0 5% !important;
      }

      @include lg {
        padding: 0 10% !important;
      }

      .page-title {
        @include md {
          font-size: 45px;
          line-height: 55px;
        }
      }
    }
  }

  form {
    .retiree-pad {
      padding: 80px 10%;
    }

    .form-check {
      padding-top: 5px !important;
    }

    .form-check-input[type=checkbox] {
      margin-left: 0;
      margin-right: 10px;
    } 

    button {
      img {
        width: 40px;
      }
    }
  }
}

.retiree-section-content {
  padding: 80px 10%;
  margin: 0;
  max-width: 100% !important;
  max-height: 100%;

  &.profile {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .icon-container {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    gap: 30px;

    button {
      &.btn-white {
        border: 2px solid #005CB2;
        background: #fff !important;
        color: #005CB2 !important;
      }
    }
  }

  .avatar-icon {
    width: 136px;
    height: 136px;
    border-radius: 100px;
    border: 4px solid #D9D9D9;
  }

  &.application-history {
    background: #F5F5F5;
    padding-top: 36px !important;

    .header {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .badge-container {
        display: flex;
        flex-direction: row;
        gap: 10px;

        .application-stat {
          margin-left: 0;
        }
      }
    }

    .application-item {
      border-radius: 20px;
      background: #FFF;
      box-shadow: 0px 0px 11px 0px rgba(125, 125, 125, 0.25);
      padding: 24px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    button {
      width: 203px !important;
      height: 56px;

      &:hover {
        background: #003D8B !important;
      }
    }

    .application-stat {
      border-radius: 6px;
      padding-left:15px;
      padding-right: 15px;
      height: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: max-content;

      &.white {
        border: 1px solid #9A9A9A;
        background:  #F0F0F0;
        width: 146px;
      }

      &.red {
        border: 1px solid #930000;
        color: #930000;
        background: #FFEBEB;
      }

      &.yellow {
        border: 1px solid #937300;
        background: #FFF4CC;
        color: #937300;
      }

      &.green {
        border: 1px solid #092319; 
        background: #E7FAF4;
      }
    }
  }
}

.p-divider {
  padding-right: calc(var(--bs-gutter-x)* .5);
  padding-left: calc(var(--bs-gutter-x)* .5);
  margin-right: auto;
  margin-left: auto;
  max-width: 1320px;

  &::before {
    border-top: 1px solid #A8A8A8;
  }
}

.card {
  &.flex {
    margin-top: 24px;

    input {
      border: 1px solid $color-med-gray;
      padding-left: 15px;
    }
  }
}

.custom-file-input::-webkit-file-upload-button {
  visibility: hidden;
}

.custom-file-input {
  padding-left: 0;
}

.custom-file-input::before {
  content: "BROWSE FILE";
    display: inline-block;
    background: #005cb2 !important;
    color: #fff;
    border: 0px solid #005cb2 !important;
    padding: 16px 30px;
    border-radius: 4px;
    outline: none;
    white-space: nowrap;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    width: 40%;
    text-align: center;
    margin-top: -10px;
}

.custom-file-input:hover::before {
  background-color: #005cb2;
  border-color: #005cb2;
}

.custom-file-input:active::before {
  background-color: #005cb2;
  border-color: #005cb2;
}

.custom-file-input:disabled::before {
  background-color: #e9ecef;
  color: #6c757d;
}

@media only screen and (max-width: 576px){
  .retiree-section-content.application-history .header{
    flex-direction: column!important;
    height: 100%;

    .badge-container {
      width: 100%;
    }
  }

  .retiree-section-container {
    .banner.application-history {
      .page-text {
        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .banner {
    .greeting-container {
      margin-left: 20px;
    }

    .page-text {
        flex-direction: column !important;
        align-items: flex-start !important;
        gap: 40px;

      .banner-image-container {
        img {
          width: 132px !important;
          height: 172px !important;
          position: relative;
          bottom: 0px!important;
        }

        position: unset !important;
        transform: unset !important;
      }
    }
  }

  .blue-card-container {
    flex-direction: column !important;
    align-items: center !important;
  }

  .profile-form {
    .icon-container {
      flex-direction: column;
      align-items: center;
    }
  }

  #modal-otherServices {
    .form-check {
      width: 100% !important;
    }
  }

  .retiree-section-container {
    &.banner {
      &.retiree-profile{
        width: 100%!important;
      }
    }
    
  }

  
  

}

@media only screen and (max-width: 992px) {
  .retiree-section-container .banner.retiree-profile .page-text .banner-image-container {
    width: 40%;
  }

  .retiree-section-container .banner.retiree-profile img {
    height: 190px;
  }

}